import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { PostGrid, CategoryGrid, FeaturedPosts, LatestPosts, BlogHeader, Layout } from 'components';
import { TextBlock } from 'slices';
import './blog.scss';

const Blog = props => {
  const blogData = useStaticQuery(graphql`
    query BlogPost {
      featuredPosts: allPrismicBlogPost(
        filter: { data: { is_featured: { eq: true } } }
        limit: 3
        sort: { fields: data___timestamp, order: DESC }
      ) {
        nodes {
          data {
            author {
              html
              text
            }
            excerpt {
              html
              text
            }
            hero_image {
              url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            timestamp(formatString: "d, MMM yyyy")
            title {
              html
              text
            }
            type
            category {
              uid
            }
            is_featured
          }
          uid
        }
      }
      lifestyle: allPrismicBlogPost(filter: { data: { category: { uid: { eq: "lifestyle" } } } }, limit: 3) {
        nodes {
          data {
            author {
              html
              text
            }
            excerpt {
              html
              text
            }
            hero_image {
              url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            timestamp
            title {
              html
              text
            }
            type
            category {
              uid
            }
          }
          uid
        }
      }
      culture: allPrismicBlogPost(filter: { data: { category: { uid: { eq: "culture" } } } }, limit: 3) {
        nodes {
          data {
            author {
              html
              text
            }
            excerpt {
              html
              text
            }
            hero_image {
              url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            timestamp
            title {
              html
              text
            }
            type
            category {
              uid
            }
          }
          uid
        }
      }
      personalFitness: allPrismicBlogPost(
        filter: { data: { category: { uid: { eq: "personal-fitness" } } } }
        limit: 3
      ) {
        nodes {
          data {
            author {
              html
              text
            }
            excerpt {
              html
              text
            }
            hero_image {
              url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            timestamp
            title {
              html
              text
            }
            type
            category {
              uid
            }
          }
          uid
        }
      }
      shopping: allPrismicBlogPost(filter: { data: { category: { uid: { eq: "shopping" } } } }, limit: 3) {
        nodes {
          data {
            author {
              html
              text
            }
            excerpt {
              html
              text
            }
            hero_image {
              url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            timestamp
            title {
              html
              text
            }
            type
            category {
              uid
            }
          }
          uid
        }
      }
      recentPosts: allPrismicBlogPost(limit: 3, sort: { order: ASC, fields: last_publication_date }) {
        nodes {
          uid
          data {
            category {
              uid
            }
            hero_image {
              url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            title {
              text
              html
            }
            author {
              html
              text
            }
            excerpt {
              html
              text
            }
            timestamp(formatString: "d MMM yyyy")
          }
        }
      }
      footerCta: prismicSettings {
        data {
          body {
            ... on PrismicSettingsBodyTextBlock {
              id
              slice_type
              primary {
                background_colour
                content {
                  html
                  text
                }
                cta_link {
                  url
                }
                cta_text {
                  html
                  text
                }
                show_logo_background
                title {
                  html
                  text
                }
              }
            }
          }
        }
      }
    }
  `);

  const { location } = props;
  const { recentPosts, featuredPosts, lifestyle, culture, personalFitness, shopping, footerCta } = blogData;

  const { nodes: newPosts } = recentPosts;
  const { nodes: featured } = featuredPosts;
  const { nodes: lifestylePosts } = lifestyle;
  const { nodes: culturePosts } = culture;
  const { nodes: personalFitnessPosts } = personalFitness;
  const { nodes: shoppingPosts } = shopping;
  const { data: footerData } = footerCta;
  const { body } = footerData;
  const footerCtaData = body[0];

  const allCategories = [
    { title: 'Lifestyle', posts: lifestylePosts },
    { title: 'Culture', posts: culturePosts },
    { title: 'Personal Fitness', posts: personalFitnessPosts },
    { title: 'Shopping', posts: shoppingPosts },
  ];

  const categories = allCategories.filter(category => category.posts.length > 0);

  return (
    <Layout location={location}>
      <section className="blog-container">
        <BlogHeader />
        {featured.length > 0 && <FeaturedPosts posts={featured} title="Featured Posts" />}
        {categories.map(category => (
          <PostGrid posts={category.posts} title={category.title} key={category.title} />
        ))}
        <CategoryGrid />
        {newPosts.length >= 3 && <LatestPosts posts={newPosts} title="Latest Posts" />}
        {footerCtaData && <TextBlock data={footerCtaData} />}
      </section>
    </Layout>
  );
};

export default Blog;
